<template>
    <page-layout-nolo :loading="isLoading" :back="backCaption">

        <v-container v-if="!isLoading">
            <v-card class="my-4 elevation-8 pa-4">
                <v-row>
                    <v-col cols="12 pb-1">
                        <div class="nolo-h5">{{ $t('orderNumber', { orderId: order.order_id })}}</div>
                        <v-divider class="dashed mt-4" />
                    </v-col>
                    <v-col cols="12 nolo-body">
                        <p><v-icon left>mdi-clock-outline</v-icon>{{ $t('orderType.' + order.order_type)}}, {{ $d(new Date(order.fulfilment_datetime.replace(' ', 'T')), 'long') }}</p>
                        <p><v-icon left>mdi-map-marker-outline</v-icon>{{ order.store_address }}</p>
                        <v-divider class="dashed my-3" />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" class="nolo-h5">
                        {{ $t('orderDetails')}}
                    </v-col>
                    <v-col cols="12">
                        <v-simple-table v-if="!isLoading">
                            <tbody>
                                <tr v-for="item in order.basket" :key="item.order_product_id">
                                    <td>
                                        <div class="font-weight-bold">{{ item.quantity }} x {{ item.name }}</div>
                                        <div class="nolo-subcopy mt-1" v-if="lineOptions(item)">
                                            {{ lineOptions(item) }}
                                        </div>
                                    </td>
                                    <td class="text-right">{{ $n(lineTotal(item), 'currency') }}</td>
                                </tr>
                            </tbody>
                        </v-simple-table>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                    <v-divider class="dashed mt-3 mb-6" />
                    <v-layout :column="isXs">
                        <v-btn @click="cancel" :block="isXs" color="secondary" :class="isXs ? 'mb-3' : 'mr-2'" outlined>{{$t('btn.cancel')}}</v-btn>
                        <v-btn v-if="(order.store_is_available && order.can_reorder) || order.force_show_reorder" :block="isXs" @click="orderAgain" color="primary white--text">{{$t('btn.orderAgain')}}</v-btn>
                    </v-layout>

                    </v-col>
                </v-row>
            </v-card>
        </v-container>

    </page-layout-nolo>
</template>

<script>

    import APICustomer from "../../api/APICustomer";
    import Constants from "../../api/Constants";

    export default {
        name: "AddressEdit",
        data() {
            return {
                isLoading: true,
                order: null,
            }
        },
        mounted() {
          const me = this;
          APICustomer.getOrder(this, this.$route.params.id)
              .then((response) => {
                me.order = response.data.data
              })
              .finally(() => {
                me.isLoading = false;
              })
        },
        methods: {
            lineOptions(item) {
                let options = [];
                for (let o in item.options) {
                    options.push(item.options[o].value);
                }
                return options.join(', ');
            },
            lineTotal(item) {
                let tot = parseFloat(item.total) + (item.quantity * parseFloat(item.tax));
                return tot.toFixed(2)
            },
            cancel() {
                this.$router.back();
            },
            orderAgain() {
                const me = this;
                if (this.order.order_type === Constants.OrderType.Table) {
                    let store = this.$store.getters['app/stores'][this.$store.getters['session/storeId']];
                    if (typeof store === 'undefined') {
                        this.$router.replace({name: '404'});
                    } else {
                        this.$store.dispatch('session/resetProgress').then(() => {
                            me.$store.commit('session/reorder', me.order.order_id);
                            me.$router.replace({name: 'table-service', params: {storeId: store.code}});
                        });
                    }
                } else {
                    this.$store.dispatch('session/resetProgress').then(() => {
                        me.$store.commit('session/location', '.ANYWHERE');
                        me.$store.commit('session/gpsLocation', {lat: 0, lng: 0});
                        me.$store.commit('session/orderType', me.order.order_type);
                        me.$store.commit('session/storeId', me.order.store_id);
                        me.$store.commit('session/reorder', me.order.order_id);

                        me.$router.replace({name: 'fulfilment-time'});
                    });
                }

            },
        },
        computed: {
            backCaption() {
                if (this.$route.name === 'order-view') {
                    return this.$t('btn.backToYourAccount');
                } else {
                    return this.$t('btn.backToYourPreviousOrders');
                }

            },

        }
    }
</script>

<style scoped>

</style>
